jQuery(function ($) {

    var winW = '';
    var winH = '';

    $(document).ready(init);

    function init() {
        resize();
        $(window).on('resize', resize);

        smoothScroll();
        bgImg();
        stickyNav();
        menu();
        select();
        slickSliders();
        search();
        video();

        modalGallery();
        accordion();

        $('input[type="file"]').on('change', function () {
            var filename = $(this).val();
            if (filename.substring(3, 11) == 'fakepath') {
                filename = filename.substring(12);
            } // Remove c:\fake at beginning from localhost chrome
            $(this).closest('.file').find('label span:first-child').html(filename);
        });

        $('iframe').parent('p').addClass('iframe-ctn');

    }

    function loader(show) {
        if (show) {
            $('body').addClass('loading');
        }
        else {
            $('body').removeClass('loading');
        }
    }

    function resize() {
        winW = window.innerWidth;
        winH = window.innerHeight;
        fixAside();
    }

    function checkURLImg(url) {
        return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
    }

    function fixAside() {

        if (winH > ($('.content-aside-inner').outerHeight() + 90) && winW > 991) {
            var headerH = $('.site-header').outerHeight() - 50;
            var footerH = $('.site-footer').outerHeight() + 60;
            $(".content-aside-inner").sticky({
                topSpacing: headerH,
                bottomSpacing: footerH
            });

            $(window).scroll(function (event) {
                var scroll = $(window).scrollTop();
                $('.site-content--faq .content-aside-inner a').each(function () {
                    var target = $(this).attr('href');
                    if (scroll >= $(target).offset().top - 280) {
                        $('.site-content--faq .content-aside-inner a').not($(this)).removeClass('active');
                        $(this).addClass('active');
                    } else {
                        $(this).removeClass('active');
                    }
                });
            });
        }

    }

    function smoothScroll() {
        $(window).on("load", function () {
            var urlHash = window.location.href.split("#")[1];
            if (urlHash && $('#' + urlHash).length)
                $('html,body').animate({
                    scrollTop: $('#' + urlHash).offset().top - 280
                }, 800);
        });

        $('a[href*="#"]:not([href="#"])').click(function () {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top - 280
                    }, 800);
                    return false;
                }
            }
        });
    }

    function bgImg() {
        $("[data-bg]").each(function () {
            var dataImg = $(this).data('bg');
            $(this).css('background-image', 'url(' + dataImg + ')');
        });
    }

    function stickyNav() {
        $(window).scroll(function () {
            if ($('.site-header').length > 0) {
                $('.site-header').toggleClass('sticky', $(window).scrollTop() > 0);
            }
        });
    }

    function accordion() {
        if ($('.accordion-items').length) {
            var allPanels = $('.accordion-items .accordion-item');
            var allAnswers = $('.accordion-items .accordion-item-answer').hide();

            $('.accordion-item .accordion-item-question').click(function (e) {
                e.preventDefault();
                $this = $(this);
                $parent = $this.closest('.accordion-item');
                $target = $this.next();

                if (!$parent.hasClass('active')) {
                    allPanels.removeClass('active');
                    $parent.addClass('active');
                    allAnswers.slideUp();
                    $target.slideDown();
                } else {
                    $parent.removeClass('active');
                    $target.slideUp();
                }

                return false;
            });
        }
    }

    function search() {
        // search modal
        $('.search-toggle').on('click', function (e) {
                e.preventDefault();

                $('#modal-search .search-field').val('');

                // Show modal
                $('#modal-search').modal();

                // Add focus to search field
                $('#modal-search').off('shown.bs.modal');
                $('#modal-search').on('shown.bs.modal', function () {
                    $('#modal-search .search-field').focus();
                });
            }
        );
    }

    function video() {
        // search modal
        $('.video-toggle').on('click', function (e) {
                e.preventDefault();

                var video = $(this).data('video');

                /*
                var newSource = $("#modal-video iframe").attr('src').replace("&autoplay=1", "&autoplay=0");*/
                $("#modal-video iframe").attr('src', video + '?rel=0&modestbranding=1&byline=0&portrait=0&autoplay=1');

                // Show modal
                $('#modal-video').modal();

                // Add focus to search field
                $('#modal-video').on('hidden.bs.modal', function () {
                    $("#modal-video iframe").attr('src', '');
                });
                $('#modal-video').on('shown.bs.modal', function () {

                });
            }
        );
    }

    function menu() {
        // menu toggle
        $('.header-toggle').on('click', function (e) {
            e.preventDefault();
            var parent = $(this).closest('.site-header');
            parent.toggleClass('open');

            $('body').toggleClass('no-scroll');
        });

        // menu mobile sub menu toggle
        if (winW <= 991) {

            $('.header-menu >.menu-item-has-children >a').on('click', function (e) {
                e.preventDefault();
                var parent = $(this).parent('.menu-item-has-children');
                var submenu = parent.children('.sub-menu');
                $('.sub-menu').not(submenu).slideUp();
                submenu.slideToggle();
            });

            $('.header-menu >.menu-item-has-children .menu-item-has-children >a').on('click', function (e) {
                e.preventDefault();
                var parent = $(this).parent('.menu-item-has-children');
                var submenu = parent.children('.sub-menu');
                $('.header-menu >.menu-item-has-children >.menu-item-has-children .sub-menu').not(submenu).slideUp();
                submenu.slideToggle();
            });
        }
    }

    function select() {
        if ($('select').length) {
            $('select:not(.swal2-select):not(.select2-multiple)').select2({
                minimumResultsForSearch: 20,
                width: 'copy'
            });

            $('.select2-multiple').select2MultiCheckboxes({});


            $('#select_towns_trigger').on('change', function () {
                var filterVals = $(this).val();
                $('.town-item').fadeOut();
                for (i = 0; i <= filterVals.length; i++) {
                    if (filterVals[i] != '') {
                        $('.town-item[data-code-commune=' + filterVals[i] + ']').fadeIn();
                    }
                }
            });


            $(document).on('click', '.multiple .select2-results__option', function () {
                var label = $(this).find('.wrap').text();
                if ($(this).attr('aria-selected') == 'false') {
                    $('.filters-tags .tags-item[data-label=' + label + ']').detach();
                } else {
                    $('.filters-tags').append($('<div class="tags-item" data-label="' + label + '">' + label + '</div>'));
                }

                if ($('.filters-tags .tags-item').length) {
                    $('.filters-remove').show();
                } else {
                    $('.filters-remove').hide();
                }
            });


            $(document).on('click', '.filters-remove', function () {
                $(".select2-multiple").val(null).trigger("change");
                $('.filters-tags').html('');
                $('.filters-remove').hide();
            });


        }
    }

    function slickSliders() {


        if ($('.home-slider').length) {
            if ($('.home-slider .slide').length > 1) {
                $('.slider-ctn').slick({
                    centerMode: true,
                    centerPadding: '88px',
                    slidesToShow: 1,
                    infinite: true,
                    dots: false,
                    arrows: true,
                    adaptiveHeight: false,
                    speed: 500,
                    cssEase: 'linear',
                    prevArrow: $('.home-slider .slider-arrows .arrow-left'),
                    nextArrow: $('.home-slider .slider-arrows .arrow-right'),
                    responsive: [
                        {
                            breakpoint: 9999,
                            settings: {
                                centerPadding: '88px'
                            }
                        },
                        {
                            breakpoint: 1199,
                            settings: {
                                centerPadding: '58px'
                            }
                        },
                        {
                            breakpoint: 991,
                            settings: {
                                centerMode: false
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                adaptiveHeight: true,
                                centerMode: false
                            }
                        },
                    ]
                });
            }
        }

        if ($('.tests-slider').length) {
            if ($('.tests-slider .slide').length > 1) {
                $('.tests-ctn').slick({
                    draggable: false,
                    centerMode: true,
                    slidesToShow: 1,
                    infinite: false,
                    dots: false,
                    arrows: true,
                    adaptiveHeight: false,
                    speed: 500,
                    cssEase: 'linear',
                    prevArrow: $('.tests-slider .test-footer .prev'),
                    nextArrow: $('.tests-slider .test-footer .next'),
                });
            }
        }

     if ($('.content-slider').length) {
            $('.content-slider').each(function () {
                var curContent = $(this);
                $(this).find('.slider-nav').slick({
                    infinite: false,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    prevArrow: curContent.find('.slider-arrows .arrow-left'),
                    nextArrow: curContent.find('.slider-arrows .arrow-right'),
                    responsive: [
                        {
                            breakpoint: 991,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                            }
                        },
                        {
                            breakpoint: 501,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            }
                        },
                    ]
                });
            });
        }
    }

    var curTest = 1;
    var nbQuestions = $('.list-test-item').length;
    var canSwitchTest = false;

    $('.test-ctn').each(function () {
        var curCtn = $(this);
        $(this).find('button').on('click', function () {
            curCtn.addClass('test-answered');
            if ($(this).hasClass('answer')) {
                curCtn.addClass('test-answered--good');
            } else {
                curCtn.addClass('test-answered--wrong');
            }
            canSwitchTest = true;
            if (curTest < nbQuestions) {
                $('.test-footer .next-link').addClass('active');
            }
            else {
                if ($('#resume_ctn_step').length > 0) {
                    $('.test-footer .resume-link').addClass('active');
                }
                $('.test-footer .next-link').remove();
            }
        });
    });

    $('.test-footer .next-link').on('click', function (e) {
        e.preventDefault();
        if (canSwitchTest) {
            curTest++;
            if ($('#test_ctn_step' + curTest).length > 0) {
                canSwitchTest = false;
                $('.test-ctn').removeClass('current');
                $('#test_ctn_step' + curTest).addClass('current');
                $('.test-footer .next-link').removeClass('active');
            }
        }
    });

    if ($('#resume_ctn_step').length > 0) {
        $('.test-footer .resume-link').on('click', function (e) {
            e.preventDefault();
            if (curTest == nbQuestions) {
                $('.test-ctn').removeClass('current');
                $('#resume_ctn_step').addClass('current');
                $('.test-footer').remove();
            }
        });
    }

    if ($('#recruitment_form').length > 0) {
        $("#recruitment_form").validate({
            rules: {
                f_civility: "required",
                f_fullname: "required",
                f_address: "required",
                f_postcode: "required",
                f_city: "required",
                f_phone: "required",
                f_license_driver: "required",
                f_car: "required",
                f_available_from: "required",
                f_available_to: "required",
                /*f_work: "required",*/
                f_resume: "required",
                f_coverletter: "required",
                f_email: {
                    required: true,
                    email: true
                },
                f_recaptcha: {
                    required: function () {
                        if (grecaptcha.getResponse() == '') {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
            },
            messages: {
                f_civility: requiredFieldTxt,
                f_fullname: requiredFieldTxt,
                f_address: requiredFieldTxt,
                f_postcode: requiredFieldTxt,
                f_city: requiredFieldTxt,
                f_phone: requiredFieldTxt,
                f_license_driver: requiredFieldTxt,
                f_car: requiredFieldTxt,
                f_available_from: requiredFieldTxt,
                f_available_to: requiredFieldTxt,
                /*f_work: requiredFieldTxt,*/
                f_resume: requiredFieldTxt,
                f_coverletter: requiredFieldTxt,
                f_email: emailFieldTxt,
                f_recaptcha: captchaFieldTxt
            },
            errorElement: "span",
            errorPlacement: function (error, element) {
                error.addClass("help-block");
                if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                    var parentElm = element.closest('.form-group');
                    parentElm.append(error);
                }
                else
                    error.insertAfter(element);
            },
            highlight: function (element, errorClass, validClass) {
                $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
            },
            submitHandler: function (form) {
                sendForm(form, 'ajax_send_form');
            }
        });
    }

    if ($('#contact_form').length > 0) {
        $("#contact_form").validate({
            rules: {
                f_fullname: "required",
                f_object: "required",
                f_message: "required",
                f_email: {
                    required: true,
                    email: true
                },
                f_recaptcha: {
                    required: function () {
                        if (grecaptcha.getResponse() == '') {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
            },
            messages: {
                f_fullname: requiredFieldTxt,
                f_object: requiredFieldTxt,
                f_message: requiredFieldTxt,
                f_email: emailFieldTxt,
                f_recaptcha: captchaFieldTxt
            },
            errorElement: "span",
            errorPlacement: function (error, element) {
                error.addClass("help-block");
                if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                    var parentElm = element.closest('.form-group');
                    parentElm.append(error);
                }
                else
                    error.insertAfter(element);
            },
            highlight: function (element, errorClass, validClass) {
                $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
            },
            submitHandler: function (form) {
                sendForm(form, 'ajax_send_contact');
            }
        });
    }

    if ($('#subscription_form').length > 0) {
        $("#subscription_form").validate({
            rules: {
                f_fullname: "required",
                f_object: "required",
                f_company: "required",
                f_phone: "required",
                f_breakfast: "required",
                f_email: {
                    required: true,
                    email: true
                },
                f_recaptcha: {
                    required: function () {
                        if (grecaptcha.getResponse() == '') {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
            },
            messages: {
                f_fullname: requiredFieldTxt,
                f_object: requiredFieldTxt,
                f_company: requiredFieldTxt,
                f_phone: requiredFieldTxt,
                f_breakfast: requiredFieldTxt,
                f_email: emailFieldTxt,
                f_recaptcha: captchaFieldTxt
            },
            errorElement: "span",
            errorPlacement: function (error, element) {
                error.addClass("help-block");
                if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                    var parentElm = element.closest('.form-group');
                    parentElm.append(error);
                }
                else
                    error.insertAfter(element);
            },
            highlight: function (element, errorClass, validClass) {
                $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
            },
            submitHandler: function (form) {
                sendForm(form, 'ajax_send_subscribtion');
            }
        });
    }


    function sendForm(form, action) {
        loader(true);
        $('.form-gle-error').remove();

        var formData = new FormData();
        formData.append("action", action);
        formData.append("formPostData", $(form).serialize());
        formData.append("security", dFormSecur);
        if ($(form).find('input:file').length > 0) {
            $(form).find('input:file').each(function (e) {
                var fileInputElement = document.getElementById($(this).attr('id'));
                formData.append($(this).attr('id'), fileInputElement.files[0]);
            });
            formData.append("async-upload", $(form).find('input:file'));
        }

        $.ajax({
            url: ajaxUri,
            type: 'post',
            data: formData,
            cache: false,
            dataType: 'json',
            processData: false,
            contentType: false,
            success: function (response) {
                loader(false);
                if (response.success) {
                    swal({
                        title: formThanks,
                        html: formSuccess,
                        type: "success"
                    });
                    $('#form_container_area').slideUp(250, function () {
                        if (!$('#form_container_area').hasClass('no-remove')) {
                            $('#form_container_area').remove();
                        }
                        if ($('.reset-form').length > 0) {
                            $("#f_type").val('').trigger('change');
                            $("#f_file").val('').closest('.file').find('label span:first-child').html('Aucun fichier sélectionné');
                            $('#signalement_form')[0].reset();
                        }
                    });
                }
                else {
                    //grecaptcha.reset();
                    if (response.errors) {
                        var formErrors = response.errors != true ? response.errors : globalErrorCode;
                    }
                    else {
                        var formErrors = globalErrorCode
                    }

                    swal({
                        title: formErrorTitle,
                        html: formErrors,
                        type: "error"
                    });
                }
            },
            error: function (response) {
                loader(false);
                swal({
                    title: formErrorTitle,
                    html: globalErrorCode,
                    type: "error"
                });
                //grecaptcha.reset();
            }
        });
    }

    $('#newsletter_form').on('submit', function (e) {
        e.preventDefault();

        var validatorNL = $(this).validate({
            rules: {
                nl_email: {
                    email: true
                }
            },
            showErrors: function (errorMap, errorList) {
            }
        });
        if (!validatorNL.form()) {
            swal({
                title: newsletterFormWarning,
                html: newsletterFormEmail,
                type: "error"
            });
        }
        else {
            var nlEmail = $(this).find('input[name="nl_email"]').val();
            var formData = new FormData();
            formData.append("action", "ajax_send_newsletter");
            formData.append("nlEmail", nlEmail);
            formData.append("security", dFormSecur);
            $.ajax({
                url: ajaxUri,
                type: 'post',
                data: formData,
                cache: false,
                dataType: 'json',
                processData: false,
                contentType: false,
                success: function (response) {
                    loader(false);
                    if (response.success) {
                        swal({
                            title: newsletterFormThanks,
                            html: newsletterFormSuccess,
                            type: "success"
                        });
                    }
                    else {
                        if (response.errors) {
                            var formErrors = response.errors != true ? response.errors : globalErrorCode;
                        }
                        else {
                            var formErrors = globalErrorCode
                        }

                        swal({
                            title: newsletterFormWarning,
                            html: formErrors,
                            type: "error"
                        });
                    }
                },
                error: function (response) {
                    loader(false);
                    swal({
                        title: newsletterFormWarning,
                        html: globalErrorCode,
                        type: "error"
                    });
                }
            });


        }
        return false;
    });

    if ($('.category-filter-map').length > 0) {
        $('.category-filter-map').prop('checked', false);

        var activFilter = $('.subheader-form').attr('data-filter');
        activFilter = parseInt(activFilter);
        if (activFilter) {
            activFilter = activFilter - 1;
            setTimeout(function () {
                $('.category-filter-map').filter(function () {
                    return this.value == activFilter
                }).prop("checked", true).change();
            }, 200);
        }

        $('.year-filter-map').val('');

        $('.category-filter-map, .year-filter-map').on('change', function () {
            var yearFilter = $('.year-filter-map').val();
            var catFilter = $('.category-filter-map:checked');
            var classFilter = [];
            if (yearFilter != '') {
                classFilter.push('y-' + yearFilter);
            }
            if (catFilter.length > 0) {
                catFilter.each(function () {
                    classFilter.push('c-' + $(this).val());
                });
            }

            if (classFilter.length > 0) {
                $('#document_list_items .card-item').addClass('hide');
                for (var i = 0; i < classFilter.length; i++) {
                    $('#document_list_items .card-item.' + classFilter[i]).removeClass('hide');
                }
            }
            else {
                $('#document_list_items .card-item').removeClass('hide');
            }

        });
    }


    if ($('.filter-cat-product').length > 0) {

        $('.filter-bees').prop('checked', false);
        $('.filter-cat-product').each(function () {
            $(this).val('');
        });

        $('.filter-cat-product, .filter-bees').on('change', function () {

            var catFilter = [];
            $('.filter-cat-product').each(function () {
                if ($(this).val() != '') {
                    catFilter.push('.' + $(this).val());
                }
            });
            if ($('.filter-bees').is(':checked')) {
                catFilter.push('.bees');
            }


            $('.no-product-msg').hide();

            if (catFilter.length > 0) {
                $('.products-list-lexical .card-item').addClass('hide');

                var catFilterClass = catFilter.join('');

                $('.products-list-lexical .card-item' + catFilterClass).removeClass('hide');
                if ($('.products-list-lexical .card-item' + catFilterClass).length == 0) {
                    $('.no-product-msg').show();
                }

            }
            else {
                $('.products-list-lexical .card-item').removeClass('hide');
            }
        });
    }


    function modalGallery() {

        $('.content-slider .image[data-toggle="modal-gallery"]').on('click', function (e) {
            e.preventDefault();
            var index = $(this).data('index');
            var slider = $(this).closest('.slick-track').html();

            $("#modal-gallery .modal-slider").append('<div class="slider"></div>');
            $("#modal-gallery .modal-slider .slider").append(slider);
            $('.modal-slider .slider').children().removeClass('slick-slide').removeClass('slick-active').removeClass('slick-cloned').removeAttr('data-slick-index').removeAttr('data-bg').removeAttr('data-target').removeAttr('aria-hidden').removeAttr('tabindex').removeAttr('data-index').removeAttr('data-toggle').removeAttr('style').children().removeClass('hidden');

           setTimeout(function () {
                $("#modal-gallery .modal-slider .slider").slick({
                    dots: false,
                    arrows: true,
                    initialSlide: index,
                    appendArrows: $("#modal-gallery .modal-slider").parents('.modal-dialog').find('.slider-arrows'),
                    prevArrow: '<div class="arrow-left slick-arrow" style=""><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 10" class="ico ico-arrow-btn"><path d="M.38,4.22h9a.26.26,0,0,0,.2-.44L7,1.25a.38.38,0,0,1,0-.52l0,0,.6-.58a.4.4,0,0,1,.56,0l4.75,4.6a.39.39,0,0,1,0,.56L8.13,9.88a.4.4,0,0,1-.56,0L7,9.33a.38.38,0,0,1,0-.52l0,0L9.6,6.25a.26.26,0,0,0,0-.37A.24.24,0,0,0,9.4,5.8H.4A.41.41,0,0,1,0,5.4V4.62a.38.38,0,0,1,.38-.4Z"></path></svg></div>',
                    nextArrow: '<div class="arrow-right slick-arrow" style=""><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 10" class="ico ico-arrow-btn"><path d="M.38,4.22h9a.26.26,0,0,0,.2-.44L7,1.25a.38.38,0,0,1,0-.52l0,0,.6-.58a.4.4,0,0,1,.56,0l4.75,4.6a.39.39,0,0,1,0,.56L8.13,9.88a.4.4,0,0,1-.56,0L7,9.33a.38.38,0,0,1,0-.52l0,0L9.6,6.25a.26.26,0,0,0,0-.37A.24.24,0,0,0,9.4,5.8H.4A.41.41,0,0,1,0,5.4V4.62a.38.38,0,0,1,.38-.4Z"></path></svg></div>',
                });
            }, 200);
            // Show modal
            $('#modal-gallery').modal();

            $('#modal-gallery').on('hidden.bs.modal', function () {
                $("#modal-gallery .modal-slider .slick").slick('unslick');
                $("#modal-gallery .slider-arrows").html('');
                $("#modal-gallery .modal-slider").html('');
            });
        });


    }

    if ($('.site-content--default, .site-content--jobs').length > 0) {
        $('.site-content--default .content-main, .site-content--jobs .content-intro').find('a').each(function () {
            var dataLink = $(this);
            var dataTarget = dataLink.attr('href');
            var modalLink = false;
            if ($(this).find('img').length > 0) {
                var targetImg = $(this).find('img');
                if (targetImg && checkURLImg(dataTarget)) {
                    modalLink = true;
                }
            }
            if (modalLink) {
                dataLink.on('click', function (e) {
                    e.preventDefault();
                    var imageLoad = new Image();
                    imageLoad.src = dataTarget;
                    imageLoad.onload = function () {
                        $('#modal_content_default .modal-image-content-default').attr('src', dataTarget);
                        $('#modal_content_default').modal();
                    };
                });
            }
        });
    }


    if ($('#signalement_form').length > 0) {
        $("#signalement_form").validate({
            rules: {
                f_type: "required",
                f_datesign: "required",
                f_city: "required",
                f_localisation: "required",
                f_recaptcha: {
                    required: function () {
                        if (grecaptcha.getResponse() == '') {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
            },
            messages: {
                f_type: requiredFieldTxt,
                f_datesign: requiredFieldTxt,
                f_city: requiredFieldTxt,
                f_localisation: requiredFieldTxt,
                f_recaptcha: captchaFieldTxt
            },
            errorElement: "span",
            errorPlacement: function (error, element) {
                error.addClass("help-block");
                if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                    var parentElm = element.closest('.form-group');
                    parentElm.append(error);
                }
                else
                    error.insertAfter(element);
            },
            highlight: function (element, errorClass, validClass) {
                $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
            },
            submitHandler: function (form) {
                sendForm(form, 'ajax_send_signalement');
            }
        });
    }


});

function recaptchaCallback() {
    $('#f_recaptcha').valid();
}

